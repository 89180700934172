import React from 'react'

const Data = () => {
  return (
    <div className="home__data">
        <h1 className="home__title">Muhammad Ilham 
        <svg xmlns="http://www.w3.org/2000/svg" class="home__hand" width="36" height="36" fill='none' viewBox="0 0 512 512"><circle fill="#FBD433" transform="matrix(2.64389 -.70843 .70843 2.64389 256 256)" r="93.505"/><path fill="#40270E" d="M118.959 278.462c69.505-2.158 212.527 1.215 274.08-.171 0 165.703-274.08 181.545-274.08.171zm85.834-84.99c-30.555-23.781-59.738-23.427-87.565-.261-12.545 10.444-18.125 4.612-12.458-9.576 5.636-14.108 12.708-26.114 21.661-35.367 37.814-39.081 72.372-4.168 88.914 34.677 5.285 12.402 2.539 20.715-10.552 10.527zm191.566 0c-30.557-23.781-59.74-23.427-87.565-.261-12.544 10.444-18.127 4.612-12.46-9.576 5.636-14.108 12.708-26.114 21.661-35.367 37.814-39.081 72.371-4.168 88.917 34.677 5.282 12.402 2.535 20.715-10.553 10.527z"/><path fill="#fff" d="M118.959 278.462c69.506-6.316 212.527-2.806 274.08-.172.786 58.937-273.259 58.212-274.08.172z"/><path fill="red" d="M163.715 377.761c46.294 40.045 132.671 41.615 184.568 0-28.257-32.14-59.077-33.112-93.282-7.846-35.265-28.197-58.875-28.995-91.286 7.846z"/></svg>
        </h1>
        <h3 className="home__subtitle">Software Developer</h3>
        <p className="home__description">Hii! As you can see i'm a Software Developer based on Yogyakarta,Indonesia.
           Lets get in touch! </p>
        <a href="#contact" className="button button--flex">
            Say Hello
            <svg
                  class="button__icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M14.2199 21.9352C13.0399 21.9352 11.3699 21.1052 10.0499 17.1352L9.32988 14.9752L7.16988 14.2552C3.20988 12.9352 2.37988 11.2652 2.37988 10.0852C2.37988 8.91525 3.20988 7.23525 7.16988 5.90525L15.6599 3.07525C17.7799 2.36525 19.5499 2.57525 20.6399 3.65525C21.7299 4.73525 21.9399 6.51525 21.2299 8.63525L18.3999 17.1252C17.0699 21.1052 15.3999 21.9352 14.2199 21.9352ZM7.63988 7.33525C4.85988 8.26525 3.86988 9.36525 3.86988 10.0852C3.86988 10.8052 4.85988 11.9052 7.63988 12.8252L10.1599 13.6652C10.3799 13.7352 10.5599 13.9152 10.6299 14.1352L11.4699 16.6552C12.3899 19.4352 13.4999 20.4252 14.2199 20.4252C14.9399 20.4252 16.0399 19.4352 16.9699 16.6552L19.7999 8.16525C20.3099 6.62525 20.2199 5.36525 19.5699 4.71525C18.9199 4.06525 17.6599 3.98525 16.1299 4.49525L7.63988 7.33525Z"
                    fill="var(--container-color)"
                  ></path>
                  <path
                    d="M10.11 14.7052C9.92005 14.7052 9.73005 14.6352 9.58005 14.4852C9.29005 14.1952 9.29005 13.7152 9.58005 13.4252L13.16 9.83518C13.45 9.54518 13.93 9.54518 14.22 9.83518C14.51 10.1252 14.51 10.6052 14.22 10.8952L10.64 14.4852C10.5 14.6352 10.3 14.7052 10.11 14.7052Z"
                    fill="var(--container-color)"
                  ></path>
            </svg>
        </a>
    </div>
  )
}

export default Data
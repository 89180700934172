import React, {useState} from 'react';
import "./qualification.css";

const Qualification = () => {
    const[toggleState, setToggleState] = useState(0);

    const toggleTab = (index) => {
        setToggleState(index);
    };

    return (
        <section className="qualification section">
            <h2 className="section__title">Qualification</h2>
            <span className="section__subtitle">What i offer?</span>

            <div className="qualification__container container">
                <div className="qualification__tabs">
                    <div 
                    className={toggleState === 1 ? 
                    "qualification__button qualification__active button--flex" : 
                    "qualification__button button--flex"}
                    onClick={() => toggleTab(1)}
                    >
                        <i className="uil uil-graduation-cap qualification__icon"></i>
                        Education
                    </div>

                    <div 
                    className={toggleState === 2 ? 
                    "qualification__button qualification__active button--flex" : 
                    "qualification__button button--flex"}
                    onClick={() => toggleTab(2)}
                    >
                        <i className="uil uil-briefcase-alt qualification__icon"></i>
                        Experience 
                    </div>
                </div>

                <div className="qualification__sections">
                    {/* Education============================= */}
                    <div 
                    className={toggleState ===1 ?
                    "qualification__content qualification__content-active" :
                    "qualification__content"
                        }
                    >
                        {/* 1st=================================== */}
                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">University</h3>
                                <span className="qualification__subtitle">Universitas Islam Indonesia</span>
                                <div className="qualificatio__calendar">
                                    <i className="uil uil-calendar-alt"></i> 2020 - Present
                                </div>
                            </div>
                            
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>

                        {/* 2nd=================================== */}
                        <div className="qualification__data">
                            <div></div>
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>

                            <div>
                                <h3 className="qualification__title">High School</h3>
                                <span className="qualification__subtitle">SMA Muhammadiyah 1 Yogyakarta</span>
                                <div className="qualificatio__calendar">
                                    <i className="uil uil-calendar-alt"></i> 2017 - 2020
                                </div>
                            </div>
                        
                        </div>

                        {/* 3rd=================================== */}
                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">Junior School</h3>
                                <span className="qualification__subtitle">SMPN 1 Karawang Barat</span>
                                <div className="qualificatio__calendar">
                                    <i className="uil uil-calendar-alt"></i> 2014 - 2017
                                </div>
                            </div>
                            
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>
                    </div>

                    {/* Experience============================= */}
                    <div
                    className={toggleState === 2 ?
                        "qualification__content qualification__content-active" :
                        "qualification__content"
                        }
                    >
                        {/* 1st=================================== */}
                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">Java Developer</h3>
                                <span className="qualification__subtitle">Internship at <br></br>PT Solusi Dua Empat Tujuh</span>
                                <div className="qualificatio__calendar"> 
                                    <i className="uil uil-calendar-alt"></i> March 2023 - September 2023
                                </div>
                            </div>
                            
                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Qualification
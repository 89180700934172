import React from 'react';
import "./about.css";
import Info from './Info';
import AboutImg from "../../assets/image.png";
import CV from "../../assets/CV_Muhammad Ilham.pdf";

const About = () => {
  return (
    <section className="about section" id="about">
        <h2 className="section__title">About Me</h2>
        <span className="section__subtitle ">My introduction</span>

        <div className="about__container container grid">
            <img src={AboutImg} alt="Photo of Me" className="about__img" />

            <div className="about__data">
                <Info/>

                <p className="about__description">Hii! My name is Muhammad Ilham Mahfuzh, but you can callme Kenra. I am a junior software developer currently studying at Universitas Islam Indonesia.
                   As a tech enthusiast, I am quick to learn and eager to grow, always driven by a strong work ethic and enthusiasm.
                    I strive to deliver the best in every project I undertake. Outside of programming,
                     I am also a cat lover and a petrol head with a deep passion for the automotive world,
                      finding balance and inspiration in the warmth of cats and the thrill of engines. Here is my CV </p>
                
                <a download ="" href={CV} className="button button--flex">
                    Download CV
                </a>
            </div>
        </div>
    </section>
  )
}

export default About
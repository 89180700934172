
export const projectsData = [
    {
        id: 1,
        image: "https://cdn.discordapp.com/attachments/832643806196662337/1273945670461554699/Screenshot_2024-08-16_at_17-02-34_Portfolio_-_Portfolio.pdf.png?ex=66c075dc&is=66bf245c&hm=ecb186f5dd454c19b43c3b9b4dae3b308afb91c0a5b33811174e64343c2af016&",
        title:'Sehat.In',
        category: "design",
        link:"https://www.figma.com/proto/Qwgvb1Kqz80LdMvKBEgKHx/Sehat.In?node-id=37-238&t=1RjeJdP5SQQGlb82-1&scaling=min-zoom&content-scaling=fixed&page-id=0%3A1&starting-point-node-id=37%3A238",
    },

    {
        id: 2,
        image: "https://cdn.discordapp.com/attachments/832643806196662337/1273945688203460700/Screenshot_2024-08-16_at_17-02-46_Portfolio_-_Portfolio.pdf.png?ex=66c075e0&is=66bf2460&hm=6fb6b619e4fd29541c2814085584122254af7ad36eb352b648fc6142e946e3ee&",
        title:"BookStack",
        category: "app",
        link:"https://github.com/IamKenra/bookstack",
    },


    
];

export const projectsNav =[
    {
        name:"all",
    },

    {
        name:"design",
    },

    {
        name:"app",
    },

];
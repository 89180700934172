import React from 'react';
import "./contact.css";

const Contact = () => {
  return (
    <section className="contact section" id="contact">
        <h2 className="section__title">Contact</h2>
        <span className="section__subtitle">Get in touch!</span>
        
        <div className="contact__container contact grid">
            <div className="contact__content">
                <div className="contact__card">

                    <i className="bx bxl-whatsapp contact__card-icon"></i>

                    <h3 className="contact__card-title">Whatsapp</h3>
                    <span className="contact__card-data">+62 852-7678-4400</span>

                    <a href="https://wa.me/6285176784400" target="_blank" rel="noopener noreferrer" className="contact__button">
                        Write Me
                        <i className="bx bx-right-arrow-alt
                         contact__button-icon"></i>
                    </a>
                </div>
            </div>

            <div className="contact__content">
                <div className="contact__card">

                    <i className="bx bx-envelope contact__card-icon"></i>

                    <h3 className="contact__card-title">Email</h3>
                    <span className="contact__card-data">mahfuzh.iam@gmail.com</span>

                    <a href="mailto:mahfuzh.iam@gmail.com" target="_blank" rel="noopener noreferrer" className="contact__button">
                        Write Me
                        <i className="bx bx-right-arrow-alt
                         contact__button-icon"></i>
                    </a>
                </div>
            </div>

            <div className="contact__content">
                <div className="contact__card">

                    <i className="bx bxl-linkedin-square contact__card-icon"></i>

                    <h3 className="contact__card-title">LinkedIn</h3>
                    <span className="contact__card-data">Muhammad Ilham</span>

                    <a href="https://www.linkedin.com/in/imiam/" target="_blank" rel="noopener noreferrer" className="contact__button">
                        Write Me
                        <i className="bx bx-right-arrow-alt
                         contact__button-icon"></i>
                    </a>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Contact
import React from 'react'

const Frontend = () => {
  return (
    <div className="skills__content">
        <h3 className="skills__title">Front End</h3>

        <div className="skills__box">
            <div className="skills__group">
                <div className="skills__data">

                    <div>
                        <h3 className="skills__name">CSS</h3>
                        <span className="skills__level">Intermediate</span>
                    </div>
                </div>

                 <div className="skills__data">

                    <div>
                        <h3 className="skills__name">Bootstrap</h3>
                        <span className="skills__level">Intermediate</span>
                    </div>
                </div>

                 <div className="skills__data">

                    <div>
                        <h3 className="skills__name">Primefaces</h3>
                        <span className="skills__level">Intermediate</span>
                    </div>
                </div>
            </div>

            <div className="skills__group">
                    <div className="skills__data">

                        <div>
                            <h3 className="skills__name">AJAX</h3>
                            <span className="skills__level">Intermediate</span>
                        </div>
                    </div>

                    <div className="skills__data">

                        <div>
                            <h3 className="skills__name">React.JS</h3>
                            <span className="skills__level">Intermediate</span>
                        </div>
                    </div>

                    <div className="skills__data">
                        <div>
                            <h3 className="skills__name">Svelte</h3>
                            <span className="skills__level">Beginner</span>
                        </div>
                    </div>
                </div>
        </div>
    </div>
  )
}

export default Frontend
import React from 'react'

const Social = () => {
  return (
    <div className="home__social">
        <a href="https://github.com/IamKenra" target="_blank" rel="noopener noreferrer" className="home__social-icon" >
            <i className="uil uil-github-alt"></i>
        </a>

        <a href="https://www.linkedin.com/in/imiam/" target="_blank" rel="noopener noreferrer" className="home__social-icon" >
            <i className="uil uil-linkedin"></i>
        </a>

        <a href="" target="_blank" rel="noopener noreferrer" className="home__social-icon" >
            <i className="uil uil-instagram"></i>
        </a>
    </div>
  )
}

export default Social
import React from 'react'

const Backend = () => {
  return (
    <div className="skills__content">
        <h3 className="skills__title">Back End</h3>

        <div className="skills__box">
            <div className="skills__group">
                <div className="skills__data">

                    <div>
                        <h3 className="skills__name">Java</h3>
                        <span className="skills__level">Intermediate</span>
                    </div>
                </div>

                 <div className="skills__data">

                    <div>
                        <h3 className="skills__name">Go</h3>
                        <span className="skills__level">Intermediate</span>
                    </div>
                </div>

                 <div className="skills__data">

                    <div>
                        <h3 className="skills__name">Spring</h3>
                        <span className="skills__level">Intermediate</span>
                    </div>
                </div>
            </div>

            <div className="skills__group">
                    <div className="skills__data">

                        <div>
                            <h3 className="skills__name">Python</h3>
                            <span className="skills__level">Intermediate</span>
                        </div>
                    </div>

                    <div className="skills__data">

                        <div>
                            <h3 className="skills__name">Go Fiber</h3>
                            <span className="skills__level">Beginer</span>
                        </div>
                    </div>

                    <div className="skills__data">
                        <div>
                            <h3 className="skills__name">Laravel</h3>
                            <span className="skills__level">Beginer</span>
                        </div>
                    </div>
                </div>
        </div>
    </div>
  )
}

export default Backend